import axios from "axios";
import { IMenuEntity } from "domain/entity/Navigation";
import { MenuRepository } from "./MenuRepo";

export const MenuRepoImpl = (): MenuRepository => {
    // const endPoint = '/v1/workspacemenu'
    // const getMenuByKey = async (menuId: string): Promise<IMenuEntity> => {
        // const result = workspaceAxiosInstance.get(`${endPoint}/${menuId}`, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //     }
        // })
        //     .then(res => {
        //         return res.data
        //     }).catch(error => {
        //         return null
        //     })
    //     return result
    // }
    const getMenuByKey = async (menuId: string, path:string=""): Promise<IMenuEntity | null> => {
        return fetch(`${path}/asset-manifest.json`, { cache: 'no-cache' })
        .then((res) => res.json())
        .then((manifest) => {
            const jsonFileName = manifest.jsonFiles['nbisiv_main_menu.json'];
            const result = axios.get(jsonFileName)
            .then(res => {
                const workspaceMenu = res.data;
                if (workspaceMenu) {
                    const menuList = workspaceMenu.workspaceMenuDTOList as IMenuEntity[];
                    return menuList.find(menu => menu.menuId === menuId)??null;
                }
                return null;
            })
            .catch(() => {
                return null;
            });
            return result; 
        })
        .catch((e) => {
            return null;
        });
    }

    return {
        getMenuByKey: getMenuByKey,
    }
}