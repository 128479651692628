export const userRequiredFieldList: string[] = [
    'name',
    'emailAddress',
    'validityFromDate',
    'validityToDate',
    'password',
    'retypePassword'
];

export const changePasswordRequiredFieldList: string[] = [
    'oldPassword',
    'password',
    'validityFromDate',
    'validityToDate',
    'password',
    'retypePassword'
];

export const UserConStant = {
    user: {
        USERNAME: "User Name",
        USERFIRSTNAME: "First Name",
        USERLASTNAME: "Last Name",
        DESCRIPTION: "Description",
        OLDPASSWORD: "Current Password",
        PASSWORD: "New Password",
        RETYPEPASSWORD: "Retype Password",
        VALIDITYDATEFROM: "Validity Date(From)",
        VALIDITYDATETO: "Validity Date(To)",
        VALIDITYDATERANGE: "Validity Date Range",
        EMAIL: "EMAIL",
        PREFERENCEENTRYPOINT: "Default Page",
        USER: "User Profile",
        LASTUPDATE: "Last Update",
        LASTUPDATEDBY: "Last Updated By",
        LASTUPDATEDDESC: "Last Update Description",
        LASTLOGINDATE: "Last Login Date",
        USERGROUPSETTING: "Administration",
        ADDUSER: "Add User",
        UPDATEUSER: "Update User",
    }
}

